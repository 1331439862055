// import s from "../../banks/index.module.scss";
import s from "./index.module.scss";
import React, {useEffect, useState} from "react";
import {useSocketContext} from "../../../context/socket-context";
import {BankInfo, BankResponse} from "../../../new-components/interfaces";
import {useParams} from "react-router-dom";
import {getBanks} from "../../../utils/http";
import {useAuthContext} from "../../../context/auth-context";
import {fetchAccountBalance, fetchAccountId, fetchRAccountId} from "../../../utils/functions";

interface Props {
    id: string;
    name: string;
}

export const BalanceDetails: React.FC<Props> = ({id, name}) => {
    const {isAuthorized} = useAuthContext();

    const {SocketState: {lastMessage}} = useSocketContext();
    const [bankData, setBankData] = useState<BankInfo[]>([]);

    const [availableBanks, setAvailableBanks] = useState<BankResponse[]>([]);

    const [availableWholesale, setAvailableWholesale] = useState(0);
    const [totalWholesale, setTotalWholesale] = useState(0);
    const [availableReserve, setAvailableReserve] = useState(0);
    const [totalReserve, setTotalReserve] = useState(0);

    const [reserveAddress, setReserveAddress] = useState<string | undefined>(undefined);
    const [digitalAddress, setDigitalAddress] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!isAuthorized) return;
        const getBanksData = async () => {
            const banks = await getBanks();
            setAvailableBanks(banks);
        };

        getBanksData();
    }, [isAuthorized, id]);

    useEffect(() => {
        let active = true;

        async function getAddresses(bankId: string, bankName: string) {
            if (isAuthorized && bankId) {
                if (!active) return;
                const reserveFundsAddress = await fetchRAccountId(bankId);
                const digitalFundsAddress = await fetchAccountId(bankId, "Main", "main");

                return {id: bankId, bank: bankName, reserve: reserveFundsAddress, digital: digitalFundsAddress};
            }
            return null;
        }

        const fetchData = async () => {
            const allBankDataPromises = availableBanks?.map(bank => getAddresses(bank.id, bank.name));
            const allBankData = await Promise.all(allBankDataPromises);
            console.log("allBankData", allBankData);
            setBankData(prev => [...prev, ...allBankData?.filter(x => x?.bank !== "Central Bank") as BankInfo[]]);
        }

        fetchData();

        return () => {
            active = false;
        }
    }, [availableBanks, isAuthorized])

    useEffect(() => {
        let active = true;

        async function getAddresses() {
            if (isAuthorized && name && id) {
                if (!active) return;
                const reserveFundsAddress = "reserve";//await fetchAccountId(id, "Reserve Funds", "Reserve Funds", authData.token)
                const digitalFundsAddress = await fetchAccountId(id, "Main", "main")

                setReserveAddress(reserveFundsAddress);
                setDigitalAddress(digitalFundsAddress);
            }
        }

        getAddresses();
        return () => {
            active = false;
        }
    }, [name, id, isAuthorized])

    useEffect(() => {
        (async () => {

            const reserve = availableBanks.find(x => x.id === id)?.reserveAddress;

            if (!digitalAddress || !reserve) return;

            const [{usable: aWholesale, total: tWholesale}, {usable: aReserve, total: tReserve},

            ] = await Promise.all([
                fetchAccountBalance(digitalAddress, 0),
                fetchAccountBalance(reserve, 1),
            ]);
            setAvailableWholesale(aWholesale);
            setTotalWholesale(tWholesale);
            setAvailableReserve(aReserve);
            setTotalReserve(tReserve);
        })()
    }, [lastMessage, digitalAddress, id, availableBanks]);

    return (
        // <div className={s.root}>

            <div className="bo">
                <div className={s.balances}>
                    <div className="account-card">
                        <h4>Available Reserve</h4>
                        <div>{availableReserve}</div>
                    </div>
                    <div className="account-card">
                        <h4>Total Reserve</h4>
                        <div>{totalReserve}</div>
                    </div>
                    <div className="account-card">
                        <h4>Available Digital<small>&nbsp;(wCBDC)</small></h4>
                        <div>{availableWholesale}</div>
                    </div>
                    <div className="account-card">
                        <h4>Total Digital<small>&nbsp;(wCBDC)</small></h4>
                        <div>{totalWholesale}</div>
                    </div>
                </div>
            </div>
        // </div>

    );
}