import axios, {AxiosResponse} from "axios";

type RtgsBalanceResponse = {
  usable: number,
  total: number
};

type TransactionEventRepr = {
  status: string,
  callerEmail: string,
  callerBank: string,
  transactionType: string,
  transactionId: string,
  transactionArgs: Record<string, unknown>,
}
interface AddressRepr {
  addressName: string;
  address: string;
}

const transactionTypes = ['transfer', 'mint', 'burn', 'create_conversion', 'fulfill_conversion', 'cancel_conversion'];

export const handleWsMessage = (event: any) => {
  console.log("EVENT", event)
  if (!event) return;
  if (transactionTypes.find((trxType) => trxType === event['transactionType'])) {
    const eventAsAny = JSON.parse(JSON.stringify(event)); // convert to any
    const eventRepr: TransactionEventRepr = {
      status: eventAsAny.status,
      callerEmail: eventAsAny.callerEmail,
      callerBank: eventAsAny.callerBank,
      transactionId: eventAsAny.transactionId,
      transactionType: eventAsAny.transactionType,
      transactionArgs: eventAsAny.transactionArgs,
    }
    const consoleMessage = `Event received: ${JSON.stringify(eventRepr, null, 1)}`;
    console.log(consoleMessage);
    return consoleMessage
  }
}

export const fetchAccountBalance: (accountId: string, assetId: number) => Promise<RtgsBalanceResponse> = async (accountId, assetId) => {
  const url: string = `${process.env.REACT_APP_RTGS_URL}/${accountId}/${assetId}`;
  return await axios.get(url).then((res: AxiosResponse) => res.data)
}


export const fetchAccountId: (bank: string, wallet: string, addressName: string) => Promise<string|undefined> = async (bank, wallet, addressName) => {
  const url: string = `${process.env.REACT_APP_BLOCKCHAIN_READER_URL}/bank/${bank}/wallet/${wallet}`;
  const response = await axios.get(url);
  return response.data.find((addressRepr: AddressRepr)=> addressRepr.addressName === addressName)?.address;
}

export const fetchRAccountId: (bank: string) => Promise<string|undefined> = async (bank) => {
  const url: string = `${process.env.REACT_APP_BLOCKCHAIN_READER_URL}/bank/${bank}/info`;
  const response = await axios.get(url);
  return response.data.reserveAddress;
}