import {Link, useLocation} from "react-router-dom";
import React from "react";
import s from "./index.module.scss";

export const Navbar = () => {
    const location = useLocation();

    return (
        <div className={s.tabs}>
            <Link to="/"  className={`${s.tab} ${location.pathname === '/' ? s.active : ''}`}>All Banks</Link>
            <Link to="/services/rtgs/details"  className={`${s.tab} ${location.pathname.includes('/details') ? s.active : ''}`}>Details</Link>
        </div>
    );
}