import s from "./index.module.scss";
import logo from "../../../../bank-logo.png"
import powered from "../../../../powered-logo.png"

export const Sidebar = () => {
    return (
        <div className={s.root}>
            <div className={s.top}>
                <div>
                    <img alt="logo" src={logo}/>
                </div>
                <div className={s.top__header}>
                    <div>Central Bank</div>
                    <div>Treasury System</div>
                </div>
                <div className={s.top__title} style={{color: "white", fontSize: "18px"}}><b>RTGS SIMULATOR</b></div>
            </div>
            <div className={s.powered}>
                <img width={150} alt="powered" src={powered}/>
                <span>v.2.0.1</span>
            </div>
        </div>
    )
}