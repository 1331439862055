import React, {ReactNode} from "react";
import {SocketContextComponent} from "../../../context/socket-component";
import s from "./index.module.scss";
import useLogin from "../../hooks/use-login";
import {Sidebar} from "./sidebar";
import {Navbar} from "./nav";

interface Props {
    children?: ReactNode;
}

export const AuthLayout: React.FC<Props> = ({children}) => {
    const {isAuthorized} = useLogin();
    // const {token} = {token: "sdf"};

    return (
        <div className={s.root}>
            <Sidebar/>
            <div className={s.content}>
                <div className={s.nav}>
                    <Navbar/>
                </div>
                <div className={s.c}>
                    {isAuthorized ? (
                        <SocketContextComponent isAuthorized={isAuthorized}>
                            {children}
                        </SocketContextComponent>
                    ) : (<div style={{textAlign: "center", marginTop: "300px", fontWeight: "bold"}}>LOADING...</div>)}
                </div>
            </div>
        </div>
    )
}