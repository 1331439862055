import React, { useState, useEffect } from "react";

interface NumberChangeEffectProps {
    number: number;
    duration: number;
}

const NumberChangeEffect: React.FC<NumberChangeEffectProps> = ({ number, duration }) => {
    const [displayNumber, setDisplayNumber] = useState(0);

    useEffect(() => {
        let startTimestamp: number;
        const step = (timestamp: number) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            setDisplayNumber(Math.floor(progress * number));
            if (progress < 1) window.requestAnimationFrame(step);
        };
        window.requestAnimationFrame(step);
    }, [number, duration]);

    return <span>{displayNumber.toLocaleString()}</span>;
};

export default NumberChangeEffect;
