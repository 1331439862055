import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getBankBalance, getBanks} from "../../../utils/http";
import {useAuthContext} from "../../../context/auth-context";
import s from "./index.module.scss"
import {Link} from "react-router-dom";
import {useSocketContext} from "../../../context/socket-context";
import {fetchAccountBalance} from "../../../utils/functions";
import {Ulid, Uuid} from "id128";
import NumberChangeEffect from "../../../new-components/common/number-effect";


interface Balances {
    availableDigital: number | string;
    totalDigital: number | string;
    availableReserve: number | string;
    totalReserve: number | string;
}

interface Props {
    id: string;
    name: string;
    reserve: string;
    digital: string;
    bank: string;
    onClick?: () => any;
}

function hexToU8a(hex: string): Uint8Array {
    hex = hex.startsWith('0x') ? hex.slice(2) : hex;

    if (hex.length % 2 !== 0) {
        throw new Error('Invalid hexadecimal string');
    }

    const u8a = new Uint8Array(hex.length / 2);

    for (let i = 0; i < hex.length; i += 2) {
        u8a[i / 2] = parseInt(hex.slice(i, i + 2), 16);
    }

    return u8a;
}


export const BankItem: React.FC<Props> = ({id, bank, digital, reserve, name, onClick}) => {
    const {isAuthorized} = useAuthContext();
    const {SocketState: {lastMessage = {}}} = useSocketContext();
    const [balances, setBalances] = useState<Balances>({
        totalDigital: 0,
        availableDigital: 0,
        availableReserve: 0,
        totalReserve: 0
    });

    function toHexString(byteArray: Uint8Array): string {
        return '0x' + Array.from(byteArray)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
    }

    const handleMintBurn = useCallback(async (status: string, type: string, digitalAddress: string, message: any) => {
        const currentBank = (message?.transactionArgs as any).bank;
        const lidHex = Ulid.fromCanonical(id);
        const compareId = toHexString(lidHex.bytes);

        if (compareId !== currentBank) return;
        if (type === "mint" || type === "burn") {
            if (status === "NEW") {
                setBalances({...balances, availableDigital: "PENDING"});

            } else if (status === "IN_BLOCK_OK") {
                const {usable: aWholesale, total: tWholesale} = await fetchAccountBalance(digitalAddress, 0);
                setBalances({...balances, availableDigital: aWholesale, totalDigital: tWholesale})
            }
        }
    }, [name, balances]);

    const handleTransfer = useCallback(async (status: string, type: string, digitalAddress: string, message: any) => {
        const lidHex = Ulid.fromCanonical(id);
        const compareId = toHexString(lidHex.bytes);

        const fromBank = message?.callerBank;
        const destBank = (message?.transactionArgs as any)?.destBank;

        if (id === fromBank || compareId === destBank) {
            if (type === "transfer") {
                if (status === "NEW") {
                    setBalances({...balances, availableDigital: "PENDING"});
                } else if (status === "IN_BLOCK_OK") {
                    const {usable: aWholesale, total: tWholesale} = await fetchAccountBalance(digitalAddress, 0);
                    setBalances({...balances, availableDigital: aWholesale, totalDigital: tWholesale})
                }
            }
        }

    }, [name, balances]);

    const [currentConversion, setCurrentConversion] = useState<number | undefined>()

    const handleConvertRedeem = useCallback(async (status: string, type: string, digitalAddress: string, message: any) => {
        const fromBank = message?.callerBank;

        if (id != fromBank) return;

        if (type === "create_conversion") {
            if (status === "NEW") {
                setBalances({...balances, availableDigital: "PENDING", availableReserve: "PENDING"});
            } else if (status === "IN_BLOCK_OK") {
                setBalances({...balances, availableDigital: "PROCESSING", availableReserve: "PROCESSING"});
                const convertId = message.events.find((x: any) => x.event === "payments.ConversionCreated")?.data.id;
                if (!convertId) return;
                setCurrentConversion(convertId)
            } else if (status === "FINALIZED_OK") {
                // await fetchBalances()
            }
        }

    }, [name, balances]);

    const handleFulfill = useCallback(async (status: string, type: string, digitalAddress: string, message: any) => {
        const conversionId = message?.transactionArgs?.conversionId;
        if (type === "fulfill_conversion") {
            if (status === "IN_BLOCK_OK" && currentConversion === conversionId) {
                await fetchBalances()
                setCurrentConversion(undefined);
            }
        }

    }, [name, balances]);


    const fetchBalances = useCallback(async () => {
        const data = await getBankBalance(digital, 0);
        const reserveData = await getBankBalance(reserve, 1);
        console.log("Balance Data", data);
        const currentBalances: Balances = {
            ...balances,
            availableDigital: data.usable,
            totalDigital: data.total,
            availableReserve: reserveData.usable,
            totalReserve: reserveData.total
        };
        setBalances(currentBalances);
    }, [digital, reserve, name]);

    useEffect(() => {
        if (!isAuthorized) return;
        const fetchBalances = async () => {
            const data = await getBankBalance(digital, 0);
            const reserveData = await getBankBalance(reserve, 1);
            const currentBalances: Balances = {
                ...balances,
                availableDigital: data.usable,
                totalDigital: data.total,
                availableReserve: reserveData.usable,
                totalReserve: reserveData.total
            };
            setBalances(currentBalances);
        };

        fetchBalances();
    }, [isAuthorized]);


    useEffect(() => {
        (async () => {
            if (!lastMessage) return;
            if (!digital || !reserve) return;
            const message: any = lastMessage;
            const transactionType = message.transactionType as string;
            const status = message.status as string;

            await handleMintBurn(status, transactionType, digital, lastMessage);
            await handleTransfer(status, transactionType, digital, lastMessage);
            await handleConvertRedeem(status, transactionType, digital, lastMessage);
            await handleFulfill(status, transactionType, digital, lastMessage);
        })()
    }, [lastMessage])

    const labels = useMemo<string[]>(() => {
        return ["PENDING", "PROCESSING"];
    }, [])

    return (
        <div className={s.bankListItem}>
            <div className={s.bankName}><Link to={`services/rtgs/details/${id}/${bank}`} className={s.unstyledLink}>{bank}</Link>
            </div>
            <div className={s.balances}>
                <div className={s.balanceContainer}>
                    <div className={s.balanceLabel}>Available Digital <small>(wCBDC)</small></div>
                    <div
                        className={`${s.balance} ${labels.includes(balances.availableDigital.toString()) ? s.pending : ''}`}>
                        {typeof balances.availableDigital === "number" ? (
                            <NumberChangeEffect number={balances.availableDigital} duration={500}/>
                        ) : <>{balances.availableDigital}</>}
                    </div>
                </div>
                <div className={s.balanceContainer}>
                    <div className={s.balanceLabel}>Total Digital <small>(wCBDC)</small></div>
                    <div
                        className={`${s.balance} ${labels.includes(balances.totalDigital.toString()) ? s.pending : ''}`}>
                        {typeof balances.totalDigital === "number" ? (
                            <NumberChangeEffect number={balances.totalDigital} duration={500}/>
                        ) : <>{balances.totalDigital}</>}
                    </div>
                </div>
                <div className={s.balanceContainer}>
                    <div className={s.balanceLabel}>Available Reserve</div>
                    <div
                        className={`${s.balance} ${labels.includes(balances.availableReserve.toString()) ? s.pending : ''}`}>
                        {typeof balances.availableReserve === "number" ? (
                            <NumberChangeEffect number={balances.availableReserve} duration={500}/>
                        ) : <>{balances.availableReserve}</>}
                    </div>
                </div>
                <div className={s.balanceContainer}>
                    <div className={s.balanceLabel}>Total Reserve</div>
                    <div
                        className={`${s.balance} ${labels.includes(balances.totalReserve.toString()) ? s.pending : ''}`}>
                        {typeof balances.totalReserve === "number" ? (
                            <NumberChangeEffect number={balances.totalReserve} duration={500}/>
                        ) : <>{balances.totalReserve}</>}
                    </div>
                </div>
            </div>
        </div>
    )
}