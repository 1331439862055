import { notification } from "antd";
import axios from "axios";

const apiService = (baseURL?: string) => {
  return axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
    validateStatus: (status) => status < 500,
  });
};

export const login = async (): Promise<{ isAuthorized: boolean }> => {
  try {
    const tokenValid = await verifyToken();
    if (tokenValid) {
      console.log("External cookie is valid");
      notification.success({
        message: "Authorized",
        description: "You are using a valid account from the DAP platform.",
        duration: 3,
        pauseOnHover: true,
        role: "status",
      });

      return { isAuthorized: true };
    } else {
      console.log("External cookie is invalid; please log in from the DAP UI");
      notification.error({
        message: "Invalid authorization",
        description: "Your DAP authorization is invalid; please log in from the DAP UI.",
        duration: 3,
        pauseOnHover: true,
        role: "status",
      });
      return { isAuthorized: false };
    }
  } catch (e) {
    console.error("Error during DAP token verification:", e);
    throw e;
  }
};

export const verifyToken = async (): Promise<boolean> => {
  try {
    const response = await apiService(
      process.env.REACT_APP_AUTH_SERVICE_URL,
    ).get("/api/v1/wholesale/token/verify");
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

export const getBanks = async () => {
  try {
    const url = `${process.env.REACT_APP_BLOCKCHAIN_READER_URL}/banks`;
    const response = await apiService(url).get(url);
    return response.data;
  } catch (error) {
    console.error("Error during getBanks:", error);
    //throw error;
  }
};

export const getBankBalance = async (accountId: string, assetId: number) => {
  try {
    const url: string = `${process.env.REACT_APP_RTGS_URL}/${accountId}/${assetId}`;
    const response = await apiService(url).get(url);
    return response.data;
  } catch (error) {
    console.error("Error during getBankDetails:", error);
    throw error;
  }
};
