import React, {useCallback, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../hooks/auth-context";
import Select from "react-dropdown-select";
import axios from "axios";
import s from "./account-selector.component.module.css";
import {useAuthContext} from "../../context/auth-context";

export interface BankRepr {
    id: string;
    value: string;
}

interface Props {
    setBank: any;
    selectedBankName?: string;
    isPage?: boolean;
}

export const AccountSelector: React.FC<Props> = ({setBank, selectedBankName, isPage}) => {
    const {isAuthorized} = useAuthContext();
    const [availableBanks, setAvailableBanks] = useState<BankRepr[]>([]);
    const [selectedValues, setSelectedValues] = useState<BankRepr[]>([]);
    const [selectedBank, setSelectedBank] = useState<string | undefined>(selectedBankName);

    useEffect(() => {
        let isActive = true;
        if (isAuthorized) {
            console.debug('Calling blockchain reader to get all banks...');
            getBanks();
        }

        async function getBanks() {
            const url = `${process.env.REACT_APP_BLOCKCHAIN_READER_URL}/banks`;
            if (isActive) {
                const response = await axios.get(url);
                const banks: BankRepr[] = response.data.map((bank: { id: string; name: string }) => {
                    return {id: bank.id, value: bank.name}
                });
                console.info('got banks: ' + JSON.stringify(banks));
                const temp = banks?.filter(x => x?.value !== "Central Bank")
                setAvailableBanks(temp);
            }
        }

        return () => {
            isActive = false;
        }
    }, [isAuthorized]);


    useEffect(() => {
        const selected = selectedValues[0];
        const {value} = selected || {};
        if (selected && setBank) {
            setSelectedBank(value)
            setBank(selected);
        }

    }, [selectedValues, setBank])

    return (
        <>
            <div className={s.root}>
                {!isPage && <Select placeholder="Select Bank"
                        wrapperClassName={s.select}
                        options={availableBanks}
                        onChange={(banks) => setSelectedValues(banks)}
                        values={selectedValues}
                        valueField="id"
                        labelField="value"
                        style={{width: "300px"}}
                ></Select>}
                <p className={s.selected}>Account ID: <b>{selectedBank || process.env.REACT_APP_BANK_NAME}</b></p>
            </div>
        </>
    )
}